// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/home/mcrowe/Programming/Personal/zavitz/pva-gatsby/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-confirmed-js": () => import("/home/mcrowe/Programming/Personal/zavitz/pva-gatsby/src/pages/confirmed.js" /* webpackChunkName: "component---src-pages-confirmed-js" */),
  "component---src-pages-index-js": () => import("/home/mcrowe/Programming/Personal/zavitz/pva-gatsby/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pending-js": () => import("/home/mcrowe/Programming/Personal/zavitz/pva-gatsby/src/pages/pending.js" /* webpackChunkName: "component---src-pages-pending-js" */)
}

